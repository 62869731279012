"use client";

import { useEffect } from "react";
import { Button } from "@/components/ui/Button";
import { Text } from "@/components/ui/Text";
import { caveat, inter } from "@/utils/fonts";
import { cn } from "@/utils/tw";
import * as Sentry from "@sentry/nextjs";
export default function GlobalError({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html lang="en" suppressHydrationWarning className={cn("scroll-smooth", inter.variable, caveat.variable)} data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, interactive-widget=resizes-content" data-sentry-element="meta" data-sentry-source-file="global-error.tsx" />

        <link rel="stylesheet" href="https://use.typekit.net/aew0jgx.css" crossOrigin="anonymous" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      </head>
      <body className="min-h-dvh overscroll-y-none bg-background font-sans antialiased">
        <Text.H5 data-sentry-element="unknown" data-sentry-source-file="global-error.tsx">Something went wrong!</Text.H5>
        <Button onClick={() => reset()} data-sentry-element="Button" data-sentry-source-file="global-error.tsx">Try again</Button>
      </body>
    </html>;
}